/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  overflow-x: hidden; 
  margin: 0;
  padding: 0;
  background-image: url("goldengate.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #9dd1e5;
}

html {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, ".SFNSText-Regular", "Helvetica Neue", "Roboto",
    "Segoe UI", sans-serif;
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle:disabled {
  cursor: not-allowed;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  &:before,
  &:after {
    content: "";
  }
  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .toggle:hover &:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + & {
    background: #56c080;
    &:before {
      left: 30px;
    }
  }
}
.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}
.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}


/* ---------- Fog ---------- */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01, #foglayer_02, #foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01, #foglayer_01 .image02,
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}
#foglayer_02, #foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/ 
#foglayer_01 .image01, #foglayer_01 .image02 {
  background: url("fog1.png") center center/cover no-repeat transparent;
}
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02{
  background: url("fog2.png") center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% { opacity: 0.3; }
  22% { opacity: 1.0; }
  40% { opacity: 0.84; }
  58% { opacity: 1.0; }
  80% { opacity: 0.48; }
  100% { opacity: 0.3; }
}
@-moz-keyframes foglayer_01_opacity {
  0% { opacity: 0.3; }
  22% { opacity: 1.0; }
  40% { opacity: 0.84; }
  58% { opacity: 1.0; }
  80% { opacity: 0.48; }
  100% { opacity: 0.3; }
}
@-o-keyframes foglayer_01_opacity {
  0% { opacity: 0.3; }
  22% { opacity: 1.0; }
  40% { opacity: 0.84; }
  58% { opacity: 1.0; }
  80% { opacity: 0.48; }
  100% { opacity: 0.3; }
}
@keyframes foglayer_01_opacity {
  0% { opacity: 0.3; }
  22% { opacity: 1.0; }
  40% { opacity: 0.84; }
  58% { opacity: 1.0; }
  80% { opacity: 0.48; }
  100% { opacity: 0.3; }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% { opacity: 1.0; }
  25% { opacity: 0.6; }
  50% { opacity: 0.3; }
  80% { opacity: 0.9; }
  100% { opacity: 1.0; }
}
@-moz-keyframes foglayer_02_opacity {
  0% { opacity: 1.0; }
  25% { opacity: 0.6; }
  50% { opacity: 0.3; }
  80% { opacity: 0.9; }
  100% { opacity: 1.0; }
}
@-o-keyframes foglayer_02_opacity {
  0% { opacity: 1.0; }
  25% { opacity: 0.6; }
  50% { opacity: 0.3; }
  80% { opacity: 0.9; }
  100% { opacity: 1.0; }
}
@keyframes foglayer_02_opacity {
  0% { opacity: 1.0; }
  25% { opacity: 0.6; }
  50% { opacity: 0.3; }
  80% { opacity: 0.9; }
  100% { opacity: 1.0; }
}

/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-moz-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-o-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}

@media only screen
  and (min-width: 280px)
  and (max-width: 767px) {
    #foglayer_01 .image01, #foglayer_01 .image02,
    #foglayer_02 .image01, #foglayer_02 .image02,
    #foglayer_03 .image01, #foglayer_03 .image02 {
      width: 100%;
    }
  }